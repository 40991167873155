
@font-face {
    font-family: "Roboto";
    src: url(./assets/Roboto/Roboto-Regular.ttf);
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   start
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   end
*/

html,body{
    background: linear-gradient(171.01deg, #010101 6.83%, #9530A6 100%);
    height: 100%;
    overflow: hidden;
}

#root{
    font-family: "Roboto";
    color: #FFF;
    overflow: auto;
    height: 100%;
}

.title1{
    font-size: 2em;
    margin: 1rem;
}

.title2{
    font-size: 1.5em;
    margin-bottom: 0.5rem
}

.bodyPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.navbar{
    display: flex;
    flex-direction: row-reverse;
    padding: 1.5em;
}

.imgNavbar{
    visibility: hidden;
}

.imgTecnos{
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-items: center;
    margin-bottom: 1.5em;
}

.imgMedium{
    height: 3em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.imgSmall{
    height: 2.5em;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-items: center;
    text-decoration: none;
    margin: 1em;
    color: #FFF;
    cursor: pointer;
    transition: 0.5s;
}

.item:active{
    color: #FFF;
}

.item:hover{
    transform: scale(1.2);
    transition: 0.5s;
}

.imgItem{
    width: 15em;
    height: 10em;
    border-radius: 8px;
    border: 1px solid #9530A6 ; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.listItens{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-items: center;
}

.myFooter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.linkFooter{
    text-decoration: none;
    font-size: 2em;
    margin: 1em;
    color: #FFF;
    cursor: pointer;
}

.linkFooter:active{
    color: #FFF;
}